import { GameAPI, ListLiveLobbiesResponse, LobbyManagerState } from "@/ts/business/api/GameAPI";
import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { logDebug } from "@/ts/business/debug";
import { isJsonDict } from "@/ts/util/json";
import { GameSource } from "@/ts/business/game/controller/source/GameSource";
import { LobbyStatus } from "@/ts/business/game/controller/status/LobbyStatus";
import { MessageInUploadedGame } from "@/ts/business/api/game/MessageInUploadedGame";
import { fetchWithThrow } from "@/app_util/fetch_util";
import { Bot } from "@/ts/business/game/Bot";
import { BotType } from "@/ts/business/game/BotType";
import { GameType } from "@/ts/business/game/GameType";
import { MessageOutAnalyseGame } from "@/ts/business/api/analysis/MessageOutAnalyseGame";
import { MessageInAnalysedGame } from "@/ts/business/api/analysis/MessageInAnalysedGame";
import { GameAnalysis } from "@/ts/business/analysis/GameAnalysis";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { Optional } from "@/ts/util/Optional";


export class WebGameAPI extends GameAPI {

    private readonly context: MessageIOContext;

    constructor() {
        super();
        this.context = MessageIOContext.createDefault();
    }

    private getAPI(): string {
        const host = window.location.host.toLowerCase();

        if (host === "localhost:3000")
            return "localhost:4343/api";

        if (host === "dev.royalur.net")
            return "devapi.royalur.net/api";

        return "api.royalur.net/api";
    }

    getCreateLobbyEndpoint(): string {
        const protocol = window.location.protocol;
        return `${protocol}//${this.getAPI()}/lobby/create`;
    }

    override async createLobby(requestPacket: PacketOut): Promise<PacketIn> {
        const requestContent = requestPacket.write(this.context);
        logDebug("createLobby request =", requestContent);

        const response = await fetchWithThrow(this.getCreateLobbyEndpoint(), {
            method: "POST",
            credentials: "include",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestContent),
        });

        const responseContent = await response.json();
        logDebug("createLobby response =", responseContent);
        if (!isJsonDict(responseContent))
            throw new Error("Invalid response: Not a JSON dictionary");

        return this.context.readPacket(responseContent);
    }

    getRecalculateUserStatsEndpoint(): string {
        const protocol = window.location.protocol;
        return `${protocol}//${this.getAPI()}/users/recalculate_stats`;
    }

    async recalculateUserStats(userPublicID: string) {
        const response = await fetchWithThrow(this.getRecalculateUserStatsEndpoint(), {
            method: "POST",
            credentials: "include",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_public_id: userPublicID,
            }),
        });
        const responseContent = await response.json() as any;
        if (responseContent["user_public_id"] === userPublicID)
            return;

        throw new Error("Mismatching user_public_id");
    }

    getRecalculateAllUserStatsEndpoint(): string {
        const protocol = window.location.protocol;
        return `${protocol}//${this.getAPI()}/users/recalculate_all_stats`;
    }

    async recalculateAllUserStats(): Promise<number> {
        const response = await fetchWithThrow(this.getRecalculateAllUserStatsEndpoint(), {
            method: "POST",
            credentials: "include",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        });
        const responseContent = await response.json() as any;
        const userCount = responseContent["user_count"];
        if (typeof userCount !== "number")
            throw new Error("Invalid response");

        return userCount;
    }

    getUploadGameEndpoint(): string {
        const protocol = window.location.protocol;
        return `${protocol}//${this.getAPI()}/games/upload`;
    }

    override async uploadGame(source: GameSource<LobbyStatus>): Promise<void> {
        if (source.lobbyStatus.get().getCompleteGameID())
            return;

        const requestContent = source.buildUploadGameMessage().write(this.context);
        logDebug("uploadGame request =", requestContent);

        const response = await fetchWithThrow(this.getUploadGameEndpoint(), {
            method: "POST",
            credentials: "include",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestContent),
        });

        const responseContent = await response.json();
        logDebug("uploadGame response =", responseContent);
        if (!isJsonDict(responseContent))
            throw new Error("Invalid response: Not a JSON dictionary");

        const message = new MessageInUploadedGame();
        message.read(this.context, responseContent);
        const finishedGameID = message.finishedGameID;
        if (finishedGameID === null)
            throw new Error("Missing finishedGameID");

        source.lobbyStatus.map(status => status.withCompleteGameID(finishedGameID));
        console.log(`Successfully uploaded game! ${finishedGameID}`);
    }

    getAnalyseGameEndpoint(): string {
        const protocol = window.location.protocol;
        return `${protocol}//${this.getAPI()}/games/analyse`;
    }

    override async analyseGame(rules: RuleSet, gameID: string): Promise<Optional<GameAnalysis>> {
        const settings = rules.getSettings();
        const gameType = GameType.getBySettings(settings);
        if (gameType === GameType.CUSTOM || gameType.isRetired)
            return Optional.empty();

        const requestContent = new MessageOutAnalyseGame(gameID).write(this.context);
        const response = await fetchWithThrow(this.getAnalyseGameEndpoint(), {
            method: "POST",
            credentials: "include",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestContent),
        });

        const responseContent = await response.json();
        if (!isJsonDict(responseContent))
            throw new Error("Invalid response: Not a JSON dictionary");

        const message = new MessageInAnalysedGame();
        message.read(this.context, responseContent);
        if (message.gameID !== gameID)
            throw new Error("Mismatching game ID");

        const analysis = message.analysis;
        if (analysis === null)
            throw new Error("Missing analysis");

        return Optional.of(GameAnalysis.create(
            rules, this.context, analysis
        ));
    }

    getLobbyManagerStateEndpoint(): string {
        const protocol = window.location.protocol;
        return `${protocol}//${this.getAPI()}/lobby/state`;
    }

    override async getLobbyManagerState(): Promise<LobbyManagerState> {
        const responseObject = await fetchWithThrow(this.getLobbyManagerStateEndpoint(), {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        });

        const notation = this.context.getLobbyNotation();
        const responseContent = await responseObject.json();
        const response = ListLiveLobbiesResponse.parse(responseContent);

        const availableBots: Bot[] = [];
        for (const bot of response.available_bots) {
            const type = BotType.getByIDOrNull(bot.type);
            const settings = GameType.getByIDOrNull(bot.settings);
            if (type === null || settings === null)
                continue;

            availableBots.push(new Bot(type, settings));
        }

        const pendingLobbies = notation.readLobbySummaries(response.pending_lobbies);
        const startedLobbies = notation.readLobbySummaries(response.started_lobbies);
        return {
            availableBots, pendingLobbies, startedLobbies,
            startedLobbiesIsExhaustive: response.started_lobbies_is_exhaustive,
        };
    }

    override getLobbyWebSocketEndpointAndURL(lobbyID: string): [string, string] {
        const httpProtocol = window.location.protocol;
        const wsProtocol = (httpProtocol === "http:" ? "ws:" : "wss:");
        const endpoint = `${wsProtocol}//${this.getAPI()}/lobby/live`;
        const url = `${endpoint}/${lobbyID}`;
        return [endpoint, url];
    }
}

export const webGameAPI = new WebGameAPI();
