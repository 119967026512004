import { GameSettings } from "@/ts/royalur/model/GameSettings";
import { RoyalUrDice } from "@/ts/business/game/royalur/RoyalUrDice";
import { TimeControl } from "@/ts/royalur/model/TimeControl";


/**
 * Provides default game settings for RoyalUr.net, along
 * with names and descriptions for game settings.
 */
export class GameType {
    static readonly FINKEL = new GameType(
        "finkel",
        GameSettings.FINKEL
            .withDice(RoyalUrDice.FOUR_BINARY),
        TimeControl.withPerMoveSeconds(21),
        "Finkel",
        "Standard rules by Irving Finkel, with safe rosette tiles "
        + "and a shorter path.",
        "This game has been setup to use the standard rules by Irving Finkel, "
        + "with safe rosette tiles and a shorter path.",
    );

    static readonly OLD_MASTERS = new GameType(
        "masters",
        GameSettings.MASTERS
            .withDice(RoyalUrDice.FOUR_BINARY),
        TimeControl.withPerMoveSeconds(21),
        "Masters (old version)",
        "Rules by James Masters, with unsafe rosette tiles "
        + "and a more challenging path.",
        "This game has been setup to use the Masters rules, "
        + "with unsafe rosette tiles and a more challenging path.",
        true,
    );

    static readonly MASTERS = new GameType(
        "masters3d",
        GameSettings.MASTERS
            .withDice(RoyalUrDice.THREE_BINARY_0EQ4),
        TimeControl.withPerMoveSeconds(21),
        "Masters",
        "Rules by James Masters, with unsafe rosette tiles, 3 dice, "
        + "and a more challenging path.",
        "This game has been setup to use the Masters rules, "
        + "with unsafe rosette tiles, 3 dice, and a more challenging path.",
    );

    static readonly BLITZ = new GameType(
        "blitz",
        GameSettings.MASTERS
            .withCapturesGrantExtraRolls(true)
            .withStartingPieceCount(5)
            .withDice(RoyalUrDice.FOUR_BINARY),
        TimeControl.withPerMoveSeconds(16),
        "Blitz",
        "Expect shorter games with unsafe rosette tiles "
        + "and captures granting extra rolls.",
        "This game has been setup to use the Blitz rules. Expect shorter games "
        + "with unsafe rosette tiles and captures granting extra rolls.",
    );

    static readonly CUSTOM = new GameType(
        "custom",
        null,
        null,
        "Custom",
        "Build your own set of rules!",
        "This game has been setup with custom rules. Who knows what's going to happen!",
    );

    static readonly DEBUG = new GameType(
        "debug",
        GameSettings.FINKEL
            .withStartingPieceCount(1)
            .withDice(RoyalUrDice.THREE_BINARY_0EQ4),
        null,
        "Debug",
        "These are debug rules, and are just here for testing the game!",
        "How did you make a debug game?",
        true,
    );

    static values(includeDebug: boolean = false): GameType[] {
        const values = [
            GameType.FINKEL,
            GameType.OLD_MASTERS,
            GameType.MASTERS,
            GameType.BLITZ,
            GameType.CUSTOM,
        ];
        if (includeDebug) {
            values.push(GameType.DEBUG);
        }
        return values;
    }

    private static createParsingMap(includeDebug: boolean = false): Record<string, GameType> {
        const map: Record<string, GameType> = {};
        map["Finkel"] = GameType.FINKEL;
        map["Masters"] = GameType.MASTERS;
        map["Blitz"] = GameType.BLITZ;
        map["Custom"] = GameType.CUSTOM;

        for (const value of GameType.values(includeDebug)) {
            map[value.id] = value;
        }
        return Object.freeze(map);
    }

    static readonly PARSING_MAP: Record<string, GameType> = GameType.createParsingMap();

    private readonly id: string;
    private readonly settings: GameSettings | null;
    private readonly timeControl: TimeControl | null;
    private readonly name: string;
    private readonly desc: string;
    private readonly introDesc: string;
    public readonly isRetired: boolean;

    constructor(
        id: string,
        settings: GameSettings | null,
        timeControl: TimeControl | null,
        name: string,
        desc: string,
        introDesc: string,
        isRetired?: boolean,
    ) {
        this.id = id;
        this.settings = settings;
        this.timeControl = timeControl;
        this.name = name;
        this.desc = desc;
        this.introDesc = introDesc;
        this.isRetired = !!isRetired;
    }

    getID(): string {
        return this.id;
    }

    hasSettings(): boolean {
        return this.settings !== null;
    }

    getSettings(): GameSettings {
        if (this.settings === null)
            throw new Error("No specific settings available");
        return this.settings;
    }

    hasTimeControl(): boolean {
        return this.timeControl !== null;
    }

    getTimeControl(): TimeControl {
        if (this.timeControl === null)
            throw new Error("No time control available");
        return this.timeControl;
    }

    getName(): string {
        return this.name;
    }

    getDesc(): string {
        return this.desc;
    }

    getIntroDesc(): string {
        return this.introDesc;
    }

    static getByID(id: string): GameType {
        const upperID = id.toUpperCase();
        for (const settings of GameType.values(true)) {
            if (settings.getID().toUpperCase() === upperID)
                return settings;
        }
        throw new Error(`Unknown RoyalUr game settings ${id}`);
    }

    static getByIDOrNull(id: string | null): GameType | null {
        if (id === null)
            return null;

        const upperID = id.toUpperCase();
        for (const settings of GameType.values(true)) {
            if (settings.getID().toUpperCase() === upperID)
                return settings;
        }
        return null;
    }

    static getBySettings(gameSettings: GameSettings): GameType {
        for (const settings of GameType.values(true)) {
            if (!settings.hasSettings())
                continue;
            if (settings.getSettings().isEquivalent(gameSettings))
                return settings;
        }
        return GameType.CUSTOM;
    }
}
