import { GameSource } from "@/ts/business/game/controller/source/GameSource";
import { LobbyStatus } from "@/ts/business/game/controller/status/LobbyStatus";
import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { z } from "zod";
import { LobbySummary } from "@/ts/business/lobby/LobbySummary";
import { Bot } from "@/ts/business/game/Bot";
import { GameAnalysis } from "@/ts/business/analysis/GameAnalysis";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { Optional } from "@/ts/util/Optional";


export const AvailableBot = z.object({
    type: z.string(),
    settings: z.string(),
});


export const ListLiveLobbiesResponse = z.object({
    available_bots: z.array(AvailableBot),
    pending_lobbies: z.array(z.record(z.string(), z.any())),
    started_lobbies_is_exhaustive: z.boolean(),
    started_lobbies: z.array(z.record(z.string(), z.any())),
});
export type ListLiveLobbiesResponse = z.infer<typeof ListLiveLobbiesResponse>;


export interface LobbyManagerState {
    availableBots: Bot[];
    pendingLobbies: LobbySummary[];
    startedLobbiesIsExhaustive: boolean;
    startedLobbies: LobbySummary[];
}


export abstract class GameAPI {

    /**
     * TODO : Take the settings and game_mode instead, and produce a message.
     * The messages for API endpoints should not be packets.
     */
    abstract createLobby(packet: PacketOut): Promise<PacketIn>;

    abstract uploadGame(source: GameSource<LobbyStatus>): Promise<void>;

    abstract analyseGame(rules: RuleSet, gameID: string): Promise<Optional<GameAnalysis>>;

    abstract getLobbyManagerState(): Promise<LobbyManagerState>;

    abstract getLobbyWebSocketEndpointAndURL(lobbyID: string): [string, string];
}
